.swiper-slide-next {
  justify-content: flex-start !important;
}

.swiper-slide-prev {
  justify-content: flex-end !important;
}

/* General styles for all Swiper elements */
.swiper {
  width: 100%;
  height: -webkit-fit-content;
  height: fit-content;
  margin: 20px auto;
  overflow: inherit;
}

.swiper-wrapper {
  height: -webkit-fit-content;
  height: fit-content;
}

/* Styles for individual slides */
.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: -webkit-fit-content;
  height: fit-content;
}

/* Styles for images inside slides */
.swiper-slide img {
  display: block;
  width: 100%;
  object-fit: cover;
  height: -webkit-fit-content;
  height: fit-content;
}

/* Styles for next and previous slide buttons */
.swiper-slide-next,
.swiper-slide-prev {
  justify-content: center;
}

/* Responsive styles for small screens */
@media screen and (max-width: 480px) {
  .swiper-slide-prev,
  .swiper-slide-next {
    justify-content: center;
  }

  .swiper {
    height: 70vh;
  }
}

/* Responsive styles for medium screens */
@media screen and (min-width: 600px) and (max-width: 900px) {
  .swiper-slide-prev,
  .swiper-slide-next {
    justify-content: center;
  }
}
